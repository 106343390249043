import React, { useLayoutEffect, useRef } from 'react';
import './style.scss';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export default function AnimatedHeading(props) {
	const el = useRef();
	useLayoutEffect(() => {
		gsap.registerPlugin(SplitText);
		const split = new SplitText(el.current, {
			type: 'words',
		});
		gsap.set(split.words, {
			translateY: 50,
			autoAlpha: 0,
		});
		gsap.to(split.words, {
			translateY: 0,
			autoAlpha: 1,
			stagger: 0.1,
			ease: 'power4.out',
			duration: 2,
		});
	});
	return (
		<section className="max-w-3xl px-6 md:px-8 pt-32 md:pt-56 pb-8">
			<h1 className="title" ref={el}>
				{props.text}
			</h1>
		</section>
	);
}
